<template lang="pug">
  div#app
    Navbar
    nav.col-sidebar.d-md-block.bg-light.collapse#sidebarMenu(v-if="signin", :data-opened="opened")
      div.position-sticky.pt-3
        div
        ul.nav.flex-column
          li.nav-item.open-close
            label.nav-link(style="cursor: pointer;")
              <svg v-if="opened" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-left feather" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z"/></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-bar-right feather" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"/></svg>
              input.d-none(type="checkbox", v-model="opened")
          hr
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(aria-current="page", to="/")
              span Dashboard
              span(data-feather="home", data-bs-toggle="tooltip", title="Dashboard")
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(to="/users")
              span(data-feather="users", data-bs-toggle="tooltip", title="Users")
              span Users
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(to="/targets2")
              span(data-feather="globe", data-bs-toggle="tooltip", title="TargetSites V2")
              span TargetSites V2
          hr
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(to="/cancel")
              span(data-feather="user-x", data-bs-toggle="tooltip", title="Canceled Users")
              span Canceled Users
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(to="/attempttargets2")
              span(data-feather="x-circle", data-bs-toggle="tooltip", title="AttemptTargets V2")
              span AttemptTargetsV2
          //- li.nav-item
            router-link.nav-link(to="/attemptusers")
              span(data-feather="user")
              span.text-decoration-line-through AttemptUsers
          //- li.nav-item
            router-link.nav-link(to="/attempttargets")
              span(data-feather="monitor")
              span.text-decoration-line-through AttemptTargets
          hr
          li.nav-item
            router-link.nav-link(to="/blog")
              span(data-feather="book", data-bs-toggle="tooltip", title="Blog")
              span Blog
          hr
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(to="/servers")
              span(data-feather="server", data-bs-toggle="tooltip", title="Servers")
              span Servers
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(to="/query")
              span(data-feather="database", data-bs-toggle="tooltip", title="DB Query")
              span DB Query
          hr
          li.nav-item(v-if="adminname!='gmo'")
            a.nav-link(href="#", role="button", @click="show_ticket_modal")
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sticky feather" viewBox="0 0 16 16"><path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z"/></svg>
              span Ticket
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(to="/tickets")
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stickies feather" viewBox="0 0 16 16"><path d="M1.5 0A1.5 1.5 0 0 0 0 1.5V13a1 1 0 0 0 1 1V1.5a.5.5 0 0 1 .5-.5H14a1 1 0 0 0-1-1H1.5z"/><path d="M3.5 2A1.5 1.5 0 0 0 2 3.5v11A1.5 1.5 0 0 0 3.5 16h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 16 9.586V3.5A1.5 1.5 0 0 0 14.5 2h-11zM3 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V9h-4.5A1.5 1.5 0 0 0 9 10.5V15H3.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V10.5a.5.5 0 0 1 .5-.5h4.293L10 14.793z"/></svg>
              span Tickets
          li.nav-item(v-if="adminname!='gmo'")
            router-link.nav-link(to="/sc")
              span(data-feather="columns", data-bs-toggle="tooltip", title="Static")
              span Static
          hr
          li.nav-item(v-if="adminname!='gmo'")
            a.nav-link(href="/portainer", target="_blank", rel="noopener")
              span Portainer
              img.favicon(src="./assets/portainer.png", data-bs-toggle="tooltip", title="Portainer")
          li.nav-item(v-if="adminname!='gmo'")
            a.nav-link(href="/redis", target="_blank", rel="noopener")
              span RedisCommander
              img.favicon(src="./assets/rediscommander.png", data-bs-toggle="tooltip", title="")
          li.nav-item(v-if="adminname!='gmo'")
            a.nav-link(href="/pgadmin4", target="_blank", rel="noopener")
              span pgAdmin4
              img.favicon(src="./assets/pgadmin.png", data-bs-toggle="tooltip", title="pgAdmin4")
          li.nav-item(v-if="adminname!='gmo'")
            a.nav-link(href="/zabbix", target="_blank", rel="noopener")
              span ZABBIX
              img.favicon(src="./assets/zabbix.png", data-bs-toggle="tooltip", title="ZABBIX")
          li.nav-item(v-if="adminname!='gmo'")
            a.nav-link(href="https://www.onwebchat.com/login.php", target="_blank", rel="noopener")
              span onWebChat
              img.favicon(src="./assets/onwebchat.png", data-bs-toggle="tooltip", title="onWebChat")



    div.container-fluid
      div.row
        main.col
          router-view
    div.modal.fade#ticket-modal(tabindex="-1", aria-labelledby="ticket-modal-label", aria-hidden="true")
      div.modal-dialog.modal-xl
        div.modal-content
          div.modal-header
            h5.modal-title#ticket-modal-label Ticket
            button.btn-close(type="button", data-bs-dismiss="modal", aria-label="Close")
          div.modal-body
            div.container-fluid
              form
                div.row.mb-3
                  label.col-12.col-lg-2.col-form-label Type
                  div.col-12.col-lg-2
                    select.form-select(v-model.number="ticket.type")
                      option(value="1") Watch
                      option(value="2") Bug
                      option(value="3") Feature
                      option(value="4") Support
                  label.col-12.col-lg-auto.col-form-label Status
                  div.col-12.col-lg-2
                    select.form-select(v-model.number="ticket.status")
                      option(value="1") New
                      option(value="2") InProgress
                      option(value="3") Resolved
                      option(value="4") Feedback
                      option(value="5") Closed
                      option(value="6") Rejected
                  label.col-12.col-lg-auto.col-form-label Priority
                  div.col-12.col-lg-2
                    select.form-select(v-model.number="ticket.priority", :data-priority="ticket.priority")
                      option(value="1") Low
                      option(value="2") Normal
                      option(value="3") High
                      option(value="4") Urgent
                      option(value="5") Immediate
                div.row.mb-3
                  label.col-12.col-lg-2.col-form-label Title
                  div.col-12.col-lg
                    input.form-control(type="text", v-model="ticket.title")
                div.row
                  div.col-12.col-lg-2.col-form-label Description
                  div.col-12.col-lg
                    div.histories(v-for="d in ticket.description", :key="d.at")
                      span.timestamp {{ moment.unix(d.at).format('YYYY-MM-DD HH:mm') }}
                      p(v-html="d.text.replace(/\\n/g,'<br>')")
                div.row.mb-3
                  div.col-12.col-lg-2
                  div.col-12.col-lg
                    textarea.form-control(rows="3", v-model="ticket_new_text")
                div.row.mb-3
                  label.col-12.col-lg-2.col-form-label Parent Ticket ID
                  div.col-12.col-lg-2
                    input.form-control(type="text", v-model.number="ticket.parent_ticket_id", @change="get_parent_ticket", :disabled="ticket.id!==0")
                    div.form-text {{ ticket_help.parent_ticket_id }}
                  label.col-12.col-lg-auto.col-form-label User ID
                  div.col-12.col-lg-2
                    input.form-control(type="text", v-model.number="ticket.user_id", @change="get_user", :disabled="ticket.id!==0")
                    div.form-text
                      router-link(:to="`/user?id=${ticket.user_id}`", target="_blank") {{ ticket_help.user_id }}
                  label.col-12.col-lg-auto.col-form-label Target ID
                  div.col-12.col-lg-2
                    input.form-control(type="text", v-model.number="ticket.target_v2_id", @change="get_target", :disabled="ticket.id!==0")
                    div.form-text
                      router-link(:to="`/target2?id=${ticket.target_v2_id}`", target="_blank") {{ ticket_help.target_v2_id }}
                div.row
                  div.col-12
                    div.histories(v-for="h in ticket.histories", :key="h.at+h.column")
                      span.timestamp {{ moment.unix(h.at).format('YYYY-MM-DD HH:mm') }}
                      p [{{ h.column }}]
                      p.ms-3(v-if="h.column=='type'") {{ types[h.value] }}
                      p.ms-3(v-else-if="h.column=='status'") {{ status[h.value] }}
                      p.ms-3(v-else-if="h.column=='priority'") {{ priority[h.value] }}
                      p.ms-3(v-else) {{ h.value }}

          div.modal-footer.justify-content-between
            //- button.btn.btn-secondary(type="button", data-bs-dismiss="modal") Close
            button.btn.btn-outline-primary(type="button", @click="new_ticket") 新規
            button.btn.btn-primary(type="button", @click="post_ticket", :disabled="ticket_posting")
              div.spinner-border.spinner-border-sm.text-light(v-if="ticket_posting", role="status")
              span(v-else) {{ ticket.id? "Update":"Register" }}
    div.tickets-select.d-none
      p 複数のTicketが見つかりました
      table.table.table-hover
        thead
          tr
            th.text-center(style="width: 50px;") ID
            th.text-center Type
              span.d-md-none <br>Status<br>Priority
            th.text-center.d-none.d-md-table-cell Status
            th.text-center.d-none.d-md-table-cell Priority
            th.text-start Title
        tbody
          tr(v-for="t in tickets", :key="t.id", style="cursor:pointer;", @click="select_ticket(t.id)")
            td.text-center {{ t.id }}
            td.text-center {{ types[t.type] }}
              span.d-md-none <br>{{ status[t.status] }}<br>
              p.d-inline-block.d-md-none.mb-0(:data-priority="t.priority", style="width:100%;") {{ priority[t.priority] }}
            td.text-center.d-none.d-md-table-cell {{ status[t.status] }}
            td.text-center.d-none.d-md-table-cell(:data-priority="t.priority") {{ priority[t.priority] }}
            td.text-start {{ t.title }}
      button.btn.btn-outline-primary(type="button", @click="new_ticket") 新規

</template>

<script>
import Navbar from '@/components/Navbar';
import feather from 'feather-icons';
import moment from 'moment';

import { Collapse, Tooltip, Modal } from 'bootstrap';

export default {
  name:"App",
  components:{
    Navbar
  },
  data (){
    return {
      moment:moment,
      opened:false,
      ticket_modal:null,
      ticket_posting:false,
      tickets:[],
      ticket:{
        id:0,
        title:"",
        type:1,
        status:1,
        priority:2,
        parent_ticket_id:null,
        user_id:null,
        target_v2_id:null,
        description:[
          // { at:1640593691, text:"ElementNotFoundを注視する"}
        ],
        histories:[]
      },
      ticket_new_text:"",
      ticket_help:{
        parent_ticket_id:"",
        user_id:"",
        target_v2_id:""
      },
      status:[
        "",
        "New",
        "InProgress",
        "Resolved",
        "Feedback",
        "Closed",
        "Rejected"
      ],
      types:[
        "",
        "Watch",
        "Bug",
        "Feature",
        "Support"
      ],
      priority:[
        "",
        "Low",
        "Normal",
        "High",
        "Urgent",
        "Immediate",
      ],
      tickets_select:null
    }
  },
  computed: {
    signin (){
      return this.$store.state.signin;
    },
    adminname (){
      return this.$store.state.adminname;
    }
  },
  mounted (){
    feather.replace();
    var self = this;
    document.querySelectorAll(".nav-link").forEach(element => {
      element.addEventListener("click", (e)=>{
        self.menu_hide();
      })
    });
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    });
    this.ticket_modal = new Modal(document.getElementById("ticket-modal"), { backdrop:"static", keyboard:false });
    this.tickets_select = document.querySelector(".tickets-select");
    this.tickets_select.remove();
    this.tickets_select.classList.remove("d-none");
  },
  methods: {
    menu_hide (){
      let c = Collapse.getInstance(document.getElementById("sidebarMenu"));
      if (c)
        c.hide();
    },
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    async show_ticket_modal (e){
      e?.preventDefault();
      let url = "";
      if (this.$route.name=='TargetV2')
        url = "/api/tickets?target="+this.$route.query.id;
      else if (this.$route.name=='User')
        url = "/api/tickets?user="+this.$route.query.id;

      if (url){
        this.loading();
        try {
          let res = await this.axios.get(url);
          if (res.data.count==0){
            await this.new_ticket();
            return;
          }
          else {
            this.tickets = res.data.tickets;
            if (res.data.count==1)
              await this.select_ticket(res.data.tickets[0].id);
            else
              this.$swal({ content:this.tickets_select, button:false });
              return;
          }
        }
        catch (e){
          await this.new_ticket();
          return;
        }
      }
      await this.new_ticket();
    },
    async select_ticket (id){
      this.ticket = this.tickets.filter(e=>e.id==id)[0];

      this.ticket_help.parent_ticket_id = "";
      this.ticket_help.user_id = "";
      this.ticket_help.target_v2_id = "";
      if (this.ticket.target_v2_id){
        await this.get_target();
        await this.get_user();
      }
      else if (this.ticket.user_id)
        await this.get_user();
      if (this.ticket.parent_ticket_id)
        await this.get_parent_ticket();
      this.ticket_new_text = "";
      this.$swal.close();
      this.ticket_modal.show();
    },
    async new_ticket (){
      this.ticket.id = 0;
      this.ticket.title = "";
      this.ticket.type = 1;
      this.ticket.status = 1;
      this.ticket.priority = 2;
      this.ticket.parent_ticket_id = null;
      this.ticket_help.parent_ticket_id = "";
      this.ticket_help.user_id = "";
      this.ticket_help.target_v2_id = "";
      this.ticket.user_id = null;
      this.ticket.target_v2_id = null;
      if (this.$route.name=='TargetV2'){
        this.ticket.target_v2_id = parseInt(this.$route.query.id, 10);
        await this.get_target();
      }
      else if (this.$route.name=='User'){
        this.ticket.user_id = parseInt(this.$route.query.id, 10);
        await this.get_user();
      }
      this.ticket.description = [];
      this.ticket.histories = [];
      this.ticket_new_text = "";
      if (this.$swal.getState().isOpen)
        this.$swal.close();
      this.ticket_modal.show();
    },
    async get_parent_ticket (){
      if (!this.ticket.parent_ticket_id){
        this.ticket.parent_ticket_id = null;
        this.ticket_help.parent_ticket_id = "";
        return;
      }
      try {
        let res = await this.axios("/api/ticket?id="+this.ticket.parent_ticket_id);
        this.ticket_help.parent_ticket_id = `${res.data.title}`;
      }
      catch(e){
        this.$swal("Error", "No Ticket", "error");
        this.ticket.parent_ticket_id = null;
        this.ticket_help.parent_ticket_id = ""
      }
    },
    async get_user (){
      if (!this.ticket.user_id){
        this.ticket.user_id = null;
        this.ticket_help.user_id = "";
        return;
      }
      try {
        let res = await this.axios("/api/v2/user?id="+this.ticket.user_id);
        this.ticket_help.user_id = `${res.data.name} ${res.data.email}`;
      }
      catch(e){
        this.$swal("Error", "No User", "error");
        this.ticket.user_id = null;
        this.ticket_help.user_id = ""
      }
    },
    async get_target (){
      if (!this.ticket.target_v2_id){
        this.ticket.target_v2_id = null;
        this.ticket_help.target_v2_id = "";
        return;
      }
      try {
        let res = await this.axios("/api/v2/target?id="+this.ticket.target_v2_id);
        this.ticket_help.target_v2_id = `${res.data.setting.label} ${res.data.setting.url}`;
        if (res.data.user_id!=this.ticket.user_id) {
          this.ticket.user_id = res.data.user_id;
          await this.get_user();
        }
      }
      catch(e){
        this.$swal("Error", "No Target", "error");
        this.ticket.target_id = null;
        this.ticket_help.target_id = "";
      }
    },
    async post_ticket (){
      let data = {
        id:this.ticket.id,
        title:this.ticket.title,
        status:this.ticket.status,
        priority:this.ticket.priority,
        body:this.ticket_new_text,
        type:this.ticket.type,
        parent_ticket_id:this.ticket.parent_ticket_id,
        user_id:this.ticket.user_id,
        target_v2_id:this.ticket.target_v2_id,
      }
      try {
        this.ticket_posting = true;
        await this.axios.post("/api/ticket", data);
        this.ticket_posting = false;
        this.ticket_modal.hide();
      }
      catch(e){
        this.$swal("Error", "", "error");
        this.ticket_posting = false;
      }
    },
    async get_ticket (id){
      this.loading();
      try {
        let res = await this.axios.get("/api/ticket?id="+id);
        this.ticket = res.data;
        this.ticket_help.parent_ticket_id = "";
        this.ticket_help.user_id = "";
        this.ticket_help.target_v2_id = "";
        if (this.ticket.target_v2_id)
          await this.get_target();
        else if (this.ticket.user_id)
          await this.get_user();
        if (this.ticket.parent_ticket_id)
          await this.get_parent_ticket();
        this.ticket_new_text = "";
        this.$swal.close();
        this.ticket_modal.show();
      }
      catch(e){
        this.$swal("Error","","error");
        console.log({e})
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.col-form-label {
  font-weight: 700;
}

.histories {
  @media (min-width: 992px) {
    display: flex;
  }

  .timestamp {
    display: block;
    font-size: .75rem;
    color: #808080;
    line-height: 1;
    @media (min-width: 992px) {
      display: unset;
      font-size: inherit;
      margin-right: 1rem;
      margin-top: unset;
      line-height: unset;
    }  
  }
  p {
    display: inline-block;
    font-size: .85rem;
    line-height: 1.2;
    margin-top: .3rem;
    margin-bottom: 0.5rem;
    @media (min-width: 992px) {
      font-size: inherit;
      line-height: inherit;
      margin-top: unset;
      margin-bottom: unset;
    }
  }
}

.tickets-select {
  th {
    vertical-align: middle;
  }
}

[data-priority="1"] {
  background-color: #EBF7FE;
}
[data-priority="3"] {
  background-color: #FDEFEF;
}
[data-priority="4"] {
  background-color: #FAD7D5;
}
[data-priority="5"] {
  background-color: #F8C8C6;
}

.col-sidebar {
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1003; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  transition: all 0.4s ease 0s;
  .open-close {
    display: none;
    + hr {
      display: none;
    }
    @media (min-width: 768px) {
      display: list-item;
      + hr {
        display: block;
      }
    }
  }
  .nav-link {
    font-weight: 500;
    color: #333;
    padding: 0.5rem 1rem;
    
    .feather {
      float: right;
      color: #727272;
    }
    span {
      transition: all 500ms;
      transition-delay: 500ms;
      margin-left: 4px;
    }
    &.router-link-exact-active {
      color: #007bff;
    }
    &:hover .feather, &.active .feather {
      color: inherit;
    }
    .favicon {
      width: 20px;
      height: 20px;
    }
  }
}
@media (min-width: 768px) {
  .col-sidebar {
    width: 200px;
    left: -150px;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1003; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    transition: all 0.4s ease 0s;
    .nav-link {
      font-weight: 500;
      color: #333;
      padding: 0.5rem 1rem;
      
      .feather, img {
        float: right;
        color: #727272;
        
      }
      span {
        transition: all 500ms;
        transition-delay: 500ms;
      }
      &.router-link-exact-active {
        color: #007bff;
      }
      &:hover .feather, &.active .feather {
        color: inherit;
      }
      .favicon {
        width: 20px;
        height: 20px;
      }
    }
    &[data-opened=true] {
      left: 0;
    }
  }
  .col-sidebar + .container-fluid {
    margin-left: 50px;
    width: calc(100% - 50px);
    transition: all 0.4s ease 0s;
  }
  .col-sidebar[data-opened=true] + .container-fluid {
    margin-left: 200px;
    width: calc(100% - 200px);
  }
}
main {
  margin-top: .5rem;
}
#app {
  font-size: .875rem;
}

.feather {
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
  margin-top: 1px;
  &:focus {
    outline: none!important;
  }
}

</style>
