import Vue from 'vue'
import VueRouter from 'vue-router'
import Dash from '../views/Dash.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dash,
    meta: { NeedAuth: true },
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import(/* webpackChunkName: "signin" */ '../views/Signin.vue'),
    meta: { NeedAuth: false },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/User/User.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/cancel',
    name: 'CancelUser',
    component: () => import(/* webpackChunkName: "cancel" */ '../views/CancelUser.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/monitors',
    name: 'Monitors',
    component: () => import(/* webpackChunkName: "monitors" */ '../views/Monitors.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/servers',
    name: 'Servers',
    component: () => import(/* webpackChunkName: "servers" */ '../views/Servers.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/targets',
    name: 'Targets',
    component: () => import(/* webpackChunkName: "targets" */ '../views/Targets.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/targets2',
    name: 'TargetsV2',
    component: () => import(/* webpackChunkName: "targets2" */ '../views/TargetsV2.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/targets2u',
    name: 'TargetsV2User',
    component: () => import(/* webpackChunkName: "targets2" */ '../views/TargetsV2user.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/target2',
    name: 'TargetV2',
    component: () => import(/* webpackChunkName: "target2" */ '../views/TargetV2.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/histories',
    name: 'Histories',
    component: () => import(/* webpackChunkName: "histories" */ '../views/Histories.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/attemptusers',
    name: 'AttemptUsers',
    component: () => import(/* webpackChunkName: "attemptusers" */ '../views/AttemptUsers.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/attempttargets',
    name: 'AttemptTargets',
    component: () => import(/* webpackChunkName: "attempttargets" */ '../views/AttemptTargets.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/attempttargets2',
    name: 'AttemptTargetsV2',
    component: () => import(/* webpackChunkName: "attempttargets2" */ '../views/AttemptTargetsV2.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/blog/blog.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/blog/article',
    name: 'Article',
    component: () => import(/* webpackChunkName: "blog" */ '../views/blog/article.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/query',
    name: 'Query',
    component: () => import(/* webpackChunkName: "query" */ '../views/Query.vue'),
    meta: { NeedAuth: true },
  },
  {
    path: '/sc',
    name: 'StaticChallenge',
    component: () => import(/* webpackChunkName: "sc" */ '../views/StaticChallenge/sc.vue'),
    meta: { NeedAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
