<template lang="pug">
.sales-chart
  canvas#chart-sales
  hr
  .table-responsive.mt-3
    table.table.table-sm.table-bordered
      thead
        tr
          th.text-end(style="min-width: 90px;") 年月
          th(v-for="m in labels") {{ m }}
      tbody
        tr
          td.text-end 月契約
          td(v-for="s in month") {{ s.toLocaleString() }}
        tr
          td.text-end 年契約
          td(v-for="s in year") {{ s.toLocaleString() }}
        tr
          td.text-end 月+年
          td(v-for="s in sums") {{ s.toLocaleString() }}
        tr
          td.text-end 月当たり
          td(v-for="s in even") {{ s.toLocaleString() }}
      thead
        tr
          th.text-end(style="min-width: 90px;") 年月
          th(v-for="m in labels") {{ m }}
      tbody
        tr
          td.text-end 月初ユーザー
          td(v-for="s in user") {{ s.toLocaleString() }}
        tr
          td.text-end 新規ユーザー
          td(v-for="s in plus") {{ s.toLocaleString() }}
        tr
          td.text-end 退会ユーザー
          td(v-for="s in cancel") {{ s.toLocaleString() }}
        tr
          td.text-end 退会ユーザー
          td(v-for="s in cancel_ids")
            a(v-for="e in s", :href="`/user?id=${e}`", target="_blank") {{ e.toLocaleString() }}, 

</template>

<script>
export default {
  name: 'SalesChart',
  data (){
    let d = new Date();
    return {
      ctx: null,
      chart: null,
      this_month: `${d.getFullYear()}/${( '000' + (d.getMonth()+1) ).slice( -2 )}`,
      annotation_now: {
        type: 'line',
        borderColor: 'black',
        borderWidth: 1,
        label: {
          display: true,
          content: 'Now',
          position: 'start'
        },
        scaleID: 'x',
        value: `${d.getFullYear()}/${( '000' + (d.getMonth()+1) ).slice( -2 )}`
      },
      annotation2021: {
        type: 'box',
        backgroundColor: 'rgba(255, 245, 157, 0.2)',
        borderWidth: 0,
        xMax: 9.5,
        xMin: -3,
        label: {
          drawTime: 'afterDraw',
          display: true,
          content: '2021',
          position: {
            x: 'center',
            y: 'start'
          }
        }
      },
      annotation2022: {
        type: 'box',
        backgroundColor: 'rgba(188, 170, 164, 0.2)',
        borderWidth: 0,
        xMax: 9.5,
        xMin: 21.5,
        label: {
          drawTime: 'afterDraw',
          display: true,
          content: '2022',
          position: {
            x: 'center',
            y: 'start'
          }
        }
      },
      annotation2023: {
        type: 'box',
        backgroundColor: 'rgba(165, 214, 167, 0.2)',
        borderWidth: 0,
        xMax: 21.5,
        xMin: 33.5,
        label: {
          drawTime: 'afterDraw',
          display: true,
          content: '2023',
          position: {
            x: 'center',
            y: 'start'
          }
        }
      },
      annotation2024: {
        type: 'box',
        backgroundColor: 'rgba(159, 168, 218, 0.2)',
        borderWidth: 0,
        xMax: 33.5,
        xMin: 45.5,
        label: {
          drawTime: 'afterDraw',
          display: true,
          content: '2024',
          position: {
            x: 'center',
            y: 'start'
          }
        }
      },
      annotation2025: {
        type: 'box',
        backgroundColor: 'rgba(255, 245, 157, 0.2)',
        borderWidth: 0,
        xMax: 45.5,
        xMin: 57.5,
        label: {
          drawTime: 'afterDraw',
          display: true,
          content: '2025',
          position: {
            x: 'center',
            y: 'start'
          }
        }
      },
      labels: [],
      month: [],
      year: [],
      even: [],
      user: [],
      plus: [],
      cancel: [],
      cancel_ids: []
    }
  },
  computed: {
    sums(){
      let result = [];
      for(let i=0;i<this.labels.length;i++)
        result.push(this.month[i]+this.year[i]);
      return result;
    }
  },
  mounted (){
    this.ctx = document.getElementById("chart-sales");
    this.get();
  },
  methods: {
    get(){
      try {
        this.axios.get("/api/saleschart")
        .then((res)=>{
          this.this_month = res.data.this_month;
          this.labels = res.data.labels;
          this.month = res.data.month;
          this.year = res.data.year;
          this.even = res.data.even;
          this.user = res.data.user;
          this.plus = res.data.plus;
          this.cancel = res.data.cancel;
          this.cancel_ids = res.data.cancel_ids;
          this.draw();
        });
      }
      catch(e){
        console.error(e);
      }
    },
    draw() {
      this.chart = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: [
            {
              type: 'line',
              label: '月当たり',
              data: this.even,
              fill: false,
              tension: 0,
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              borderColor: "rgba(54, 162, 235, 0.5)",
            },
            {
              type: 'line',
              label: 'ユーザー',
              data: this.user,
              fill: false,
              tension: 0,
              backgroundColor: "rgba(255, 159, 64, 0.5)",
              borderColor: "rgba(255, 159, 64, 0.5)",
              yAxisID: 'y2',
            },
            {
              label: '月',
              data: this.month,
              backgroundColor: this.month_bg
            },
            {
              label: '年',
              data: this.year,
              backgroundColor: this.year_bg
            },
          ]
        },
        options: {
          title: {
            display: true,
            text: '売上'
          },
          scales: {
            y: {
              stacked: true,
            },
            x: {
              stacked: true,
              grid: {
                display: false
              }
            },
            y2: {
              type: 'linear',
              display: true,
              position: 'right',
              max: 200,
              grid: {
                drawOnChartArea: false,
              },
              title: {
                display: true,
                text: 'ユーザー数'
              }
            }
          },
        plugins: {
          annotation: {
            annotations: {
              annotation_now: this.annotation_now,
              annotation2021: this.annotation2021,
              annotation2022: this.annotation2022,
              annotation2023: this.annotation2023,
              annotation2024: this.annotation2024,
              annotation2025: this.annotation2025
            }
          }
          },
        }
      });
    },
    month_bg (e){
      if (this.labels[e.index] <= this.this_month)
        return 'rgba(219,39,91,0.5)';
      else
        return 'rgba(219,39,91,0.25)';
    },
    year_bg (e){
      if (this.labels[e.index] <= this.this_month)
        return 'rgba(130,201,169,0.5)';
      else
        return 'rgba(130,201,169,0.25)';
    },
  }
}
</script>

<style lang="scss" scoped>
.sales-chart ::v-deep {
  td {
    text-align: right;
  }
}
</style>